<template>
	<input type="file" ref="file" @change="loadImage($event)" accept="image/*" style="display: none;" id="fileref">
	<div>
		<wrapper class="whitewrapper">
			<cropper
				ref="cropper"
				class="cropper"
				image-restriction="fit-area"
				:transitions="true"
				:src="image.src"
				@change="updateSize"
				:default-boundaries="boundaries"

			/>
			<vertical-buttons>
				<square-button title="Open" >
					<img :src="require('../assets/open.png')" @click.stop="open()"/>
				</square-button>
				<square-button title="Zoom In" >
					<img :src="require('../assets/zoom-in.svg')" @click.stop="zoom(2)" />
				</square-button>
				<square-button title="Zoom Out" >
					<img :src="require('../assets/zoom-out.svg')" @click.stop="zoom(0.5)"/>
				</square-button>
				<square-button title="Flip Horizontal" >
					<img :src="require('../assets/flip-horizontal.svg')" @click.stop="flip(true, false)"/>
				</square-button>
				<square-button title="Flip Vertical">
					<img :src="require('../assets/flip-vertical.svg')"  @click.stop="flip(false, true)" />
				</square-button>
				<square-button title="Rotate Clockwise">
					<img :src="require('../assets/rotate-clockwise.svg')"  @click.stop="rotate(90)" />
				</square-button>
				<square-button title="Rotate Counter-Clockwise" >
					<img :src="require('../assets/rotate-counter-clockwise.svg')" @click.stop="rotate(-90)" />
				</square-button>
				<!--
				<square-button title="fill" >
					<img :src="require('../assets/fill.svg')" @click.stop="fill()"/>
				</square-button>
				-->
				<square-button title="Share" >
					<img :src="require('../assets/share.png')" @click.stop="share()"/>
				</square-button>
				<square-button title="Download" >
					<img :src="require('../assets/download.svg')" @click.stop="crop()"/>
				</square-button>
			</vertical-buttons>
		</wrapper>
	</div>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css';
import { CircleStencil, Cropper, RectangleStencil  } from 'vue-advanced-cropper';
import SquareButton from './SquareButton';
import Wrapper from './ExampleWrapper';
import VerticalButtons from './VerticalButtons';


function download(blob, filename)
{
    var a = document.createElement('a');
    //var blob = new Blob([content], {'type':contentType});
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
}


// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
	name: 'Asdf',
    components: {
		Cropper, SquareButton, Wrapper, VerticalButtons
	},
	data() {
		return {
			image: {
				src: "",
				type: null
			},
			image_cropped: {
				src: null,
				type: null
			},
			imagename: "",
			stencil: "circle-stencil",
			size: {
				width: null,
				height: null,
			},
		};
	},
	methods: {
		crop() {
			const { coordinates, canvas, } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;
			this.image_cropped = canvas.toDataURL();
			name = this.imagename.name.replace(new RegExp("(\.[^.]+)" + '$'), '_cropped$&');
			
			canvas.toBlob((blob) => {
				download(blob, name);
			}, this.image.type);
		},
		share() {
			const { coordinates, canvas, } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;
			//this.image_cropped = canvas.toDataURL();
			name = this.imagename.name.replace(new RegExp("(\.[^.]+)" + '$'), '_cropped$&');

			canvas.toBlob((blob) => {
				var file = new File([blob],name,{type: this.image.type});

				if (navigator.canShare && navigator.canShare({ files: [file] })) {
						navigator.share({
				    files: [file],
				    title: name,
				    text: '',
				  })
				  .then(() => console.log('Share was successful.'))
				  .catch((error) => console.log('Sharing failed', error));
				} else {
					alert("Your system doesn't support sharing");
				}
			}, this.image.type);
		},
		reset() {
			this.image = {
				src: null,
				type: null
			}
		},
		flip(x,y) {
			this.$refs.cropper.flip(x,y);

		},
		rotate(angle) {
			this.$refs.cropper.rotate(angle);
		},
		fill() {
			var image = this.$refs.cropper.image;
			var canvas = document.createElement("canvas");
			context = canvas.getContext('2d');
			context.drawImage(image,0,0);
			context.fillRect(0,0,25,25);

			console.log(this.$refs.cropper);
		},
		open() {
			document.getElementById("fileref").click();
		},
		boundaries({ cropper, imageSize }) {
			return {
				width: cropper.clientWidth,
				height: cropper.clientHeight,
			};
		},
		updateSize({ coordinates }) {
			this.size.width = Math.round(coordinates.width);
			this.size.height = Math.round(coordinates.height);
		},
		zoom(factor) {
			this.$refs.cropper.zoom(factor);
		},
		move(direction) {
			if (direction === 'left') {
				this.$refs.cropper.move(-this.size.width / 4);
			} else if (direction === 'right') {
				this.$refs.cropper.move(this.size.width / 4);
			} else if (direction === 'top') {
				this.$refs.cropper.move(0, -this.size.height / 4);
			} else if (direction === 'bottom') {
				this.$refs.cropper.move(0, this.size.height / 4);
			}
		},
		loadImage(event) {
			this.reset();
			// Reference to the DOM input element
			const { files } = event.target;
			// Ensure that you have a file before attempting to read it
			if (files && files[0]) {
				// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				// 2. Create the blob link to the file to optimize performance:
				const blob = URL.createObjectURL(files[0]);
				this.imagename = files[0];
				
				// Create a new FileReader to read this image binary data
				const reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.image" refers to the image of Vue component
					this.image = {
						// Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
						src: blob,
						// Determine the image type to preserve it during the extracting the image from canvas:
						type: getMimeType(e.target.result, files[0].type),
					};
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsArrayBuffer(files[0]);
			}
		},
	},
	destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
}
</script>

<style>
/*
	It may be necessary to set limits on the size of the cropper, otherwise the cropper image will try to fill all the available space.
*/


.whitewrapper { 	background:  #FFFFFF;  }

.cropper {
	max-height: 500px;
	max-width:  80%;
	margin-left:  100px;
	height:  500px;
	background:  #FFFFFF;

}

</style>

<style lang="scss">
.vertical-buttons {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
}
</style>